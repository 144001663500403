/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentSheetEventsEnum, Stripe } from '@capacitor-community/stripe';
import { Capacitor } from '@capacitor/core';
import {
    AlertController,
    IonInput,
    LoadingController,
    MenuController,
    ModalController,
    NavController,
} from '@ionic/angular';
import { Order, OrderItem } from '@paystory/models';
import { CartService } from '@paystory/services/cart.service';
import { HelperService } from '@paystory/services/helper.service';
import { PlaceService } from '@paystory/services/place.service';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { VariationSelectionPage } from 'src/pages/place/components/variation-selection/variation-selection.page';

@Component({
    selector: 'app-order-cart',
    templateUrl: './order-cart.page.html',
    styleUrls: ['./order-cart.page.scss'],
})
export class OrderCartPage implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('dragItem') dragItem: ElementRef;
    @ViewChild('track') container: ElementRef;
    @ViewChild('before') before: ElementRef;
    @ViewChild('after') after: ElementRef;
    @ViewChild('end') end: ElementRef;
    @ViewChild('tableNumberInput') tableNumberInput: IonInput;
    dragWidth: number;
    active: boolean;
    currentX: number;
    initialX: number;
    xOffset: number;

    cartTotal$ = this.cartService.getTotal();
    cartTip$ = this.cartService.getTipPercent();
    cartFees$ = this.cartService.getFees();
    cartTotalWithFee$ = this.cartService.getTotalWithFee();
    isApplePayAvailable = false;
    isGooglePayAvailable = false;
    isPayPalAvailable =
        Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('PayPal');

    loading = false;
    paymentLoading = false;
    keyboardSize = 0;

    discountCode;
    discountLoading = false;

    orderType;

    constructor(
        private navCtrl: NavController,
        private modalController: ModalController,
        public cartService: CartService,
        private helper: HelperService,
        private route: ActivatedRoute,
        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
        private ngZone: NgZone,
        public placeService: PlaceService,
        private menuCtrl: MenuController,
        private cdr: ChangeDetectorRef
    ) {
        this.active = false;
        this.xOffset = 0;
    }

    onTableNumberInputFocus() {
        if (this.tableNumberInput) {
            this.tableNumberInput.setFocus();
        }
    }

    ngOnDestroy() {
        this.menuCtrl.enable(true);
    }

    ngAfterViewInit() {
        this.menuCtrl.enable(false);
        this.ngZone.runOutsideAngular(() => {
            this.dragWidth =
                this.container.nativeElement.clientWidth -
                this.dragItem.nativeElement.clientWidth +
                20;

            this.container.nativeElement.addEventListener(
                'touchstart',
                this.dragStart,
                false
            );
            this.container.nativeElement.addEventListener(
                'touchend',
                this.dragEnd,
                false
            );
            this.container.nativeElement.addEventListener(
                'touchmove',
                this.drag,
                false
            );

            this.container.nativeElement.addEventListener(
                'mousedown',
                this.dragStart,
                false
            );
            this.container.nativeElement.addEventListener(
                'mouseup',
                this.dragEnd,
                false
            );
            this.container.nativeElement.addEventListener(
                'mousemove',
                this.drag,
                false
            );
        });
    }

    async ngOnInit() {
        const loading = await this.loadingCtrl.create({
            translucent: true,
        });
        loading.present();
        if (Capacitor.isNativePlatform()) {
            if (Capacitor.getPlatform() == 'android') {
                this.isGooglePayAvailable = true;
            }
            if (Capacitor.getPlatform() == 'ios') {
                this.isApplePayAvailable = true;
            }
        }

        try {
            const placeId = this.route.snapshot.params?.placeId;
            if (placeId) {
                await this.cartService.openPlace({ id: placeId });
            }
            if (!this.placeService.activePlace?.id) {
                await this.placeService.loadPlace(placeId);
                this.cartService.data.paymentMethod =
                    this.placeService.activePlace?.allowedPaymentMethods[0];
                setTimeout(() => {
                    this.cartService.data.paymentMethod =
                        this.placeService.activePlace?.allowedPaymentMethods[0];
                    this.cartService.data.tip = 0;

                    console.log(
                        'this.cartService.data.paymentMethod',
                        this.cartService.data.paymentMethod
                    );
                    this.cdr.detectChanges();
                }, 100);
            }

            this.orderType = await this.placeService.getSelectedOrderType();
        } catch (error) {}
        loading.dismiss();
    }

    back() {
        this.navCtrl.back();
    }

    async payment() {
        console.log('payment');
        this.paymentLoading = true;
        let message;

        if (
            !(await lastValueFrom(this.cartService.products.pipe(take(1))))
                .length
        ) {
            message = 'Keine Produkte im Warenkorb';
        }

        if (
            this.cartService.data?.paymentMethod === 'stripe' &&
            (await lastValueFrom(this.cartTotalWithFee$.pipe(take(1)))) < 0.5
        ) {
            message = 'Bezahlung erst ab 0,50 €';
        }

        if (
            this.cartService.place?.type === 'restaurant' &&
            this.cartService.data?.type === 'order' &&
            !this.cartService.data?.details?.tableNumber?.length
        ) {
            message = 'Gib bitte eine Tischnummer ein';
        }

        if (
            this.cartService.place?.type === 'restaurant' &&
            this.cartService.data?.type === 'delivery' &&
            (!this.cartService.data?.deliveryAddress?.address?.length ||
                !this.cartService.data?.deliveryAddress?.zipCode?.toString()
                    ?.length ||
                !this.cartService.data?.deliveryAddress?.city?.length)
        ) {
            message = 'Gib bitte eine Lieferadresse an';
        }

        if (message) {
            this.helper.showToast(message);

            this.paymentLoading = false;
            this.animateBack();
            return;
        }

        let error = false;

        const loading = await this.loadingCtrl.create({
            translucent: true,
        });
        await loading.present();
        let order: Order;
        try {
            // Create order
            order = await this.cartService.createOrder();

            const checkoutResponse = await this.cartService.checkout(order.id, {
                paymentUi: true,
            });
            if (!(window as any).isTest && order.paymentMethod === 'stripe') {
                const {
                    paymentIntent,
                    ephemeralKey,
                    customer,
                    merchantDisplayName,
                    sandbox,
                } = checkoutResponse;
                const webIsLoaded = new Promise(resolve => {
                    Stripe.addListener(PaymentSheetEventsEnum.Loaded, () =>
                        resolve(true)
                    );
                });
                await Stripe.createPaymentSheet({
                    paymentIntentClientSecret: paymentIntent,
                    customerEphemeralKeySecret: ephemeralKey,
                    customerId: customer,
                    merchantDisplayName,
                    applePayMerchantId: 'merchant.de.paystory',
                    enableApplePay: true,
                    countryCode: 'DE',
                    enableGooglePay: true,
                    GooglePayIsTesting: sandbox,
                });
                if (!Capacitor.isNativePlatform()) {
                    await webIsLoaded;
                }
                await loading.dismiss();
                const response = await Stripe.presentPaymentSheet();
                if (
                    response.paymentResult !== PaymentSheetEventsEnum.Completed
                ) {
                    error = true;
                    this.animateBack();
                }
            } else {
                await loading.dismiss();
            }
        } catch (e) {
            this.animateBack();
            await loading.dismiss();
            console.error('Error!', e);
            error = true;
        }

        if (!error && order?.id) {
            const placeId = this.cartService.placeId;
            await this.cartService.clear();
            await this.navCtrl.navigateRoot(['order-placed', order.id], {
                queryParams: { placeId: placeId },
            });
        }
    }

    async variation_selection(product: OrderItem) {
        this.modalController
            .create({
                component: VariationSelectionPage,
                componentProps: product,
            })
            .then(async modalElement => {
                await modalElement.present();
            });
    }

    async selectPaymentMethod($event: any) {
        if (this.cartService.data?.paymentMethod !== $event?.detail?.value) {
            await this.cartService.selectPaymentMethod($event?.detail?.value);
        }
    }

    async changeTableNumber() {
        try {
            const picker = await this.alertCtrl.create({
                header: 'An welchem Tisch sitzt du?',
                translucent: true,
                inputs: [
                    {
                        name: 'tableNumber',
                        placeholder: 'Tischnr.',
                        value:
                            this.cartService.data?.details?.tableNumber || '',
                    },
                ],
                buttons: [
                    {
                        text: 'Abbrechen',
                        role: 'cancel',
                    },
                    {
                        text: 'Speichern',
                        handler: value => {
                            const tableNumber = value.tableNumber;
                            this.setTableNumber(tableNumber);
                        },
                    },
                ],
            });

            await picker.present();
        } catch (e) {}
    }

    async setTableNumber(tableNumber) {
        await this.cartService.setDetail('tableNumber', tableNumber || '');
    }

    async checkCoupon() {
        this.discountLoading = true;
        try {
            const data = await lastValueFrom(
                await this.cartService.checkCoupon(this.discountCode)
            );
            this.helper.showToast('Gutschein angewendet');
        } catch (error) {
            this.helper.showToast('Gutschein nicht gültig');
        } finally {
            setTimeout(() => {
                this.discountLoading = false;
            }, 100);
        }
    }

    dragStart = e => {
        this.ngZone.runOutsideAngular(() => {
            if (e.cancelable) {
                e.preventDefault();
                e.stopPropagation();
            }

            this.dragWidth =
                this.container.nativeElement.clientWidth -
                this.dragItem.nativeElement.clientWidth +
                20;

            if (e.type === 'touchstart') {
                this.initialX = e.touches[0].clientX - this.xOffset;
            } else {
                this.initialX = e.clientX - this.xOffset;
            }

            if (e.target === this.dragItem.nativeElement) {
                this.active = true;
            }
        });
    };

    dragEnd = e => {
        this.ngZone.runOutsideAngular(() => {
            if (e.cancelable) {
                e.preventDefault();
                e.stopPropagation();
            }

            if (this.currentX < this.dragWidth - 5) {
                this.animateBack();
            } else {
                this.completed();
            }

            this.initialX = this.currentX;
            this.active = false;
        });
    };

    drag = e => {
        this.ngZone.runOutsideAngular(() => {
            if (e.cancelable) {
                e.preventDefault();
                e.stopPropagation();
            }
            if (this.active) {
                if (e.type === 'touchmove') {
                    this.currentX = e.touches[0].clientX - this.initialX;
                } else {
                    this.currentX = e.clientX - this.initialX;
                }

                // xOffset = currentX;

                if (this.currentX > 0 && this.currentX < this.dragWidth) {
                    this.setTranslate(this.currentX, this.dragItem);
                }
            }
        });
    };

    setTranslate = (xPos, el) => {
        this.ngZone.runOutsideAngular(() => {
            el.nativeElement.style.transform =
                'translate3d(' + xPos + 'px, ' + 0 + 'px, 0)';
            this.end.nativeElement.style.opacity = 0;
            if (xPos > this.dragWidth / 2) {
                this.after.nativeElement.style.opacity = 1;
                this.before.nativeElement.style.opacity = 0;
                this.container.nativeElement.style.background =
                    'linear-gradient(218deg, rgb(29 210 201) 0%, rgb(89 251 171) 100%)';
            } else {
                this.after.nativeElement.style.opacity = 0;
                this.before.nativeElement.style.opacity = 1;
                this.container.nativeElement.style.background =
                    'linear-gradient(  218deg , rgb(61 137 187) 0%, rgb(75 166 231) 100%)';
            }
        });
    };

    animateBack = () => {
        // turn off/on animations to speed up the fallback
        this.ngZone.runOutsideAngular(() => {
            this.setTranslate(0, this.dragItem);

            this.container.nativeElement.style.background = 'rgb(0 0 0 / 30%)';
            this.dragItem.nativeElement.classList.toggle('animate');
            this.container.nativeElement.classList.toggle('animate');
            this.before.nativeElement.classList.toggle('animate');
            this.after.nativeElement.classList.toggle('animate');

            setTimeout(() => {
                this.container.nativeElement.style.background =
                    'rgb(0 0 0 / 30%)';
                this.dragItem.nativeElement.classList.toggle('animate');
                this.container.nativeElement.classList.toggle('animate');
                this.before.nativeElement.classList.toggle('animate');
                this.after.nativeElement.classList.toggle('animate');
            }, 200);
        });
    };

    completed = () => {
        this.ngZone.runOutsideAngular(() => {
            this.payment();
            this.end.nativeElement.style.opacity = 1;
            this.after.nativeElement.style.opacity = 0;
            this.before.nativeElement.style.opacity = 0;
        });
    };
}
